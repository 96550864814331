@import "assets/scss/colors.scss";
@import "assets/scss/fonts.scss";

html,
body {
  overflow-x: hidden;
}

input:focus {
  outline: none;
}

.view_container {
  position: relative;
  width: 100%;
  max-width: 1500px;
  display: block;
  margin: auto;
}

.duals_project_card {
  position: relative;
  width: auto;
  padding: 30px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(8, 30, 41, 0.08),
    0px 16px 24px -8px rgba(8, 30, 41, 0.08);
  border-radius: 12px;
  height: auto;

  & .view_wrapper {
    display: flex;
    align-items: flex-start;
    margin-right: 10px;

    & .spacer {
      flex-grow: 1;
    }
  }

  & .title {
    font-family: $fontFamilyPoppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
    color: #081e29;
  }

  & .desc {
    font-family: $fontFamilyPoppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #7a8890;
    width: 100%;
    max-width: 500px;
    margin-top: 12px;
  }

  & .nav_wrapper {
    display: flex;
    align-items: center;
    height: 90px;

    & .nav_item {
      position: relative;
      width: 40px;
      height: 40px;
      background: #dda97a;
      box-shadow: 0px 16px 24px -8px rgba(221, 169, 122, 0.4);
      border-radius: 15px;
      cursor: pointer;
      transition: all 0.4s !important;

      &:hover {
        box-shadow: none;
        background-color: $gold;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }
  }
}

.heroXY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.block_loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9000 !important;
  background-color: rgb(255, 255, 255);
  overflow-y: hidden;
}

.heroXY {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.burgerMenuBtn {
  width: 48px;
  height: 48px;
  margin-top: -9px;
  margin-right: 8px;
  border-radius: 6px !important;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
}

.viewBurgerMenu {
  position: fixed;
  top: 0;
  right: -200%;
  bottom: -20px;
  left: 100%;
  background-image: url("assets/images/home-first-section-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  z-index: 1000;
  transition: all 0.2s ease-in-out;

  .fillHeight {
    height: 64px;
  }

  .brandWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 20px;

    & .appStoreBtnWrappers {
      display: flex;
      align-items: center;
      height: auto;
      justify-content: center;
    }

    & .brandLogo {
      height: 64px;
      width: 100%;
      max-width: 120px;
      display: block;
      margin: auto;
      margin-top: 64px;
    }
  }

  .storeImage {
    width: auto;
    height: 48px;
    margin: 10px;
  }

  &[open-view="open"] {
    right: 0;
    left: 0;
  }

  & .header {
    display: flex;
    align-items: center;
    height: 64px;

    & .spacer {
      flex-grow: 1;
    }

    & .closeBtn {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }
  }

  & .mobileMenuViewWrapper {
    display: flex;
    align-items: start;
    justify-content: center;
    height: 100%;
    margin-top: 32px;
    width: 100%;

    & .linkBtn {
      width: 100%;
      text-decoration: none;

      &[login-btn="true"] {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 13.3px;
        text-align: center;
        color: $gold3;
        border: 1px solid $gold;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        padding-top: 18px;
        padding-bottom: 18px;
        padding-left: 40px;
        padding-right: 40px;
      }

      &[become-tutor-btn="true"] {
        width: 100%;
        max-width: 300px;
        display: block;
        margin: auto;
        height: auto;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 13.3px;
        text-align: center;
        color: $white;
        border: 1px solid $gold;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        padding-top: 18px;
        padding-bottom: 18px;
        background-color: $gold;
      }

      & .become_a_tutor-btn {
        width: 125.54px;
        height: 43px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 0.912rem;
        line-height: 13.3px;
        text-align: center;
        color: $gold;
        background: $white;
        border: 1px solid $gold;
        box-sizing: border-box;
        border-radius: 16px;
        cursor: pointer;
        margin-right: 16px;
      }
    }

    & .menu {
      font-family: $fontFamilyPoppins;
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;

      & li {
        font-size: 32px;
        text-align: center;
        margin: 20px auto;
        width: 100%;
      }
    }
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.animateFlicker {
  -webkit-animation: flickerAnimation 0.9s infinite;
  -moz-animation: flickerAnimation 0.9s infinite;
  -o-animation: flickerAnimation 0.9s infinite;
  animation: flickerAnimation 0.9s infinite;
}
