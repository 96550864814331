@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.__page_not_found {
	background-image: url("../../assets/images/404-gb.svg");
	background-repeat: no-repeat;

	.__header {
		display: flex;
		flex-direction: row;

		.__duals_logo {
			flex: 1;
			margin-top: 60px;
			padding-left: 10%;
		}

		.__header_btn {
			display: flex;
			justify-content: flex-end;
			margin-right: 100px;
			margin-top: 60px;
			margin-bottom: 31px;

			.__become_a_tutor_btn {
				width: 201px;
				height: 43px;
				font-family: $fontFamilyPoppins;
				font-style: normal;
				font-weight: bold;
				font-size: 1rem;
				line-height: 13.3px;
				text-align: center;
				color: $gold;
				background: $white;
				border: 1px solid $gold;
				box-sizing: border-box;
				border-radius: 16px;
				cursor: pointer;
			}
		}
	}

	.__container {
		width: 800px;
		margin: 0 auto;
		margin-bottom: 103px;

		.__not_found_img {
			display: flex;
			justify-content: center;
		}

		.__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 48px;

			.__title {
				height: 27px;
				font-family: $fontFamilyPoppins;
				font-style: normal;
				font-weight: $fontWeight600;
				font-size: 1.5rem;
				line-height: 26px;
				text-align: center;
				color: $black3;
				margin: 0;
				margin-bottom: 18px;
			}

			.__description {
				width: 838px;
				height: 30px;
				font-family: $fontFamilyPoppins;
				font-style: normal;
				font-weight: $fontWeight500;
				font-size: 1.25rem;
				line-height: 30px;
				text-align: center;
				color: $grey;
				margin: 0;
				padding: 0;
			}
		}
	}
}

@media (max-width: 768px) {
	.__page_not_found {
		background-image: url("../../assets/images/404-gb.svg");
		background-repeat: no-repeat;

		.__header {
			display: flex;
			flex-direction: row;

			.__duals_logo {
				flex: 1;
				margin-top: 60px;
				padding-left: 10%;
			}

			.__header_btn {
				display: flex;
				justify-content: flex-end;
				margin-right: 70px;
				margin-top: 60px;
				margin-bottom: 31px;

				.__become_a_tutor_btn {
					width: 201px;
					height: 43px;
					font-family: $fontFamilyPoppins;
					font-style: normal;
					font-weight: bold;
					font-size: 1rem;
					line-height: 13.3px;
					text-align: center;
					color: $gold;
					background: $white;
					border: 1px solid $gold;
					box-sizing: border-box;
					border-radius: 16px;
					cursor: pointer;
				}
			}
		}

		.__container {
			width: 100%;
			margin: 0 auto;
			margin-bottom: 103px;

			.__not_found_img {
				display: flex;
				justify-content: center;
			}

			.__content {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 48px;

				.__title {
					height: 27px;
					font-family: $fontFamilyPoppins;
					font-style: normal;
					font-weight: $fontWeight600;
					font-size: 1.5rem;
					line-height: 26px;
					text-align: center;
					color: $black3;
					margin: 0;
					margin-bottom: 18px;
				}

				.__description {
					width: 100%;
					height: 30px;
					font-family: $fontFamilyPoppins;
					font-style: normal;
					font-weight: $fontWeight500;
					font-size: 1.25rem;
					line-height: 30px;
					text-align: center;
					color: $grey;
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}

@media (max-width: 428px) {
	.__page_not_found {
		background-image: url("../../assets/images/404-gb.svg");
		background-repeat: no-repeat;

		.__header {
			display: flex;
			flex-direction: row;

			.__duals_logo {
				flex: 1;
				margin-top: 60px;
				padding-left: 10%;
			}

			.__header_btn {
				display: flex;
				justify-content: flex-end;
				margin-right: 30px;
				margin-top: 60px;
				margin-bottom: 31px;

				.__become_a_tutor_btn {
					width: 120px;
					height: 33px;
					font-family: $fontFamilyPoppins;
					font-style: normal;
					font-weight: bold;
					font-size: 0.7rem;
					line-height: 13.3px;
					text-align: center;
					color: $gold;
					background: $white;
					border: 1px solid $gold;
					box-sizing: border-box;
					border-radius: 16px;
					cursor: pointer;
				}
			}
		}

		.__container {
			width: 100%;
			margin: 0 auto;
			margin-bottom: 80px;

			.__not_found_img {
				display: flex;
				justify-content: center;
			}

			.__content {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 48px;

				.__title {
					height: 27px;
					font-family: $fontFamilyPoppins;
					font-style: normal;
					font-weight: $fontWeight600;
					font-size: 1.5rem;
					line-height: 26px;
					text-align: center;
					color: $black3;
					margin: 0;
					margin-bottom: 18px;
				}

				.__description {
					width: 90%;
					height: 30px;
					font-family: $fontFamilyPoppins;
					font-style: normal;
					font-weight: $fontWeight500;
					font-size: 1.25rem;
					line-height: 30px;
					text-align: center;
					color: $grey;
					margin: 0;
					padding: 0;
					margin-bottom: 20px;
				}
			}
		}
	}
}
