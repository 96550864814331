@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.__footer {
  background-image: url("../../assets/images/footer-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  .__container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 38px;
    margin-right: 130px;
    position: relative;

    .__row {
      display: flex;
      flex-direction: row;

      .__column {
        flex: 1;

        .__logo {
          cursor: pointer;
        }

        .__app_store {
          margin-top: 24px;
          margin-bottom: 10px;
        }
      }

      .__first_column {
        padding-left: 114px;

        .__store_img {
          width: 108px;
          height: 36.56px;
          border: 2px solid transparent;
        }

        .__store_img:hover {
          border: 2px solid $gold;
          border-radius: 9px;
        }

        .__store_img:focus {
          border: 2px solid $gold3;
          border-radius: 9px;
        }
      }
    }

    .__first_row {
      flex: 1;
      margin-top: 105px;
    }

    .__heading {
      font-family: $fontFamilyPoppins;
      font-style: normal;
      font-weight: $fontWeight500;
      font-size: 1.125rem;
      line-height: normal;
      letter-spacing: -0.02em;
      color: $blackPearl;
      padding-bottom: 24px;
    }

    .__links {
      font-family: $fontFamilyPoppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: normal;
      color: $blackPearl;
      cursor: pointer;
      padding-bottom: 8px;
    }

    .__links:hover {
      text-decoration: underline;
    }

    .__second_row {
      display: flex;
      align-items: center;
      height: 65px;
      border-top: 1px solid $aeroBlue;
      margin-top: 90px;
      margin-left: 114px;
    }

    .__copy_rights {
      font-family: $fontFamilyPoppins;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: normal;
      text-align: left;
      color: $blackPearl;
      width: 100%;
    }

    .__social_icons {
      text-align: right;

      img {
        margin-left: 18px;
      }
    }

    .__top_arrow {
      cursor: pointer;
    }
  }
}

@media (max-width: 1024px) {
  .__footer {
    background: url("../../assets/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;

    .__container {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 0px;
      margin-right: 0px;
      position: relative;

      .__row {
        display: flex;
        flex-direction: row;
        margin-left: 0px;
        margin-top: 25px;

        .__column {
          flex: 1;
          text-align: center;
        }

        .__store_col {
          display: flex;
          flex-direction: row;
          justify-content: center;
          div {
            a {
              img {
                width: 108px;
                height: 36px;
              }

              .__play_store_icon {
                padding-left: 8px;
              }

              &:hover {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }

              &:focus {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }
            }
          }
        }

        .__arrow {
          text-align: center;
          margin-right: 0px;
        }

        .__heading {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: $fontWeight500;
          font-size: 1.125rem;
          line-height: normal;
          letter-spacing: -0.02em;
          color: $blackPearl;
          padding-bottom: 24px;
        }

        .__links {
          height: 24px;
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 24px;
          color: $blackPearl;
          cursor: pointer;
        }

        .__second_row {
          display: flex;
          align-items: center;
          height: 65px;
          border-top: 1px solid $aeroBlue;
          background-color: #f8f8f8;
        }

        .__copy_rights {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: normal;
          text-align: center;
          color: $blackPearl;
          margin-right: 20px;
          padding-left: 0px;
        }

        .__social_links {
          a {
            img {
              width: 22px;
              height: 22px;
              padding: 6px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .__footer {
    background: url("../../assets/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;

    .__container {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 0px;
      margin-right: 0px;
      position: relative;

      .__row {
        display: flex;
        flex-direction: row;
        margin-left: 0px;
        margin-top: 25px;

        .__column {
          flex: 1;
          text-align: center;
        }

        .__store_col {
          display: flex;
          flex-direction: row;
          justify-content: center;
          div {
            a {
              img {
                width: 108px;
                height: 36px;
              }

              .__play_store_icon {
                padding-left: 8px;
              }

              &:hover {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }

              &:focus {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }
            }
          }
        }

        .__arrow {
          text-align: center;
          margin-right: 0px;
        }

        .__heading {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: $fontWeight500;
          font-size: 1.125rem;
          line-height: normal;
          letter-spacing: -0.02em;
          color: $blackPearl;
          padding-bottom: 24px;
        }

        .__links {
          height: 24px;
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 24px;
          color: $blackPearl;
          cursor: pointer;
        }

        .__second_row {
          display: flex;
          align-items: center;
          height: 65px;
          border-top: 1px solid $aeroBlue;
          background-color: #f8f8f8;
        }

        .__copy_rights {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: normal;
          text-align: center;
          color: $blackPearl;
          margin-right: 20px;
        }

        .__social_links {
          a {
            img {
              width: 22px;
              height: 22px;
              padding: 6px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .__footer {
    background: url("../../assets/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;

    .__container {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 0px;
      margin-right: 0px;
      position: relative;

      .__row {
        display: flex;
        flex-direction: row;
        margin-left: 0px;
        margin-top: 25px;

        .__column {
          flex: 1;
          text-align: center;
        }

        .__store_col {
          display: flex;
          flex-direction: row;
          justify-content: center;
          div {
            a {
              img {
                width: 108px;
                height: 36px;
              }

              .__play_store_icon {
                padding-left: 8px;
              }

              &:hover {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }

              &:focus {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }
            }
          }
        }

        .__arrow {
          text-align: center;
          margin-right: 0px;
        }

        .__heading {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: $fontWeight500;
          font-size: 1.125rem;
          line-height: normal;
          letter-spacing: -0.02em;
          color: $blackPearl;
          padding-bottom: 24px;
        }

        .__links {
          height: 24px;
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 24px;
          color: $blackPearl;
          cursor: pointer;
        }

        .__second_row {
          display: flex;
          align-items: center;
          height: 65px;
          border-top: 1px solid $aeroBlue;
          background-color: #f8f8f8;
        }

        .__copy_rights {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: normal;
          text-align: center;
          color: $blackPearl;
          margin-right: 20px;
        }

        .__social_links {
          a {
            img {
              width: 22px;
              height: 22px;
              padding: 6px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 428px) {
  .__footer {
    background: url("../../assets/images/footer-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;

    .__container {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 0px;
      margin-right: 0px;
      position: relative;

      .__row {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
        margin-top: 20px;

        .__column {
          flex: 1;
          text-align: left;
        }

        .__store_col {
          display: flex;
          flex-direction: row;

          div {
            a {
              img {
                width: 108px;
                height: 36px;
              }

              .__play_store_icon {
                padding-left: 8px;
              }

              &:hover {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }

              &:focus {
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              }
            }
          }
        }

        .__arrow {
          text-align: right;
          margin-right: 20px;
        }

        .__heading {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: $fontWeight500;
          font-size: 1.125rem;
          line-height: normal;
          letter-spacing: -0.02em;
          color: $blackPearl;
          padding-bottom: 24px;
        }

        .__links {
          height: auto;
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: 24px;
          color: $blackPearl;
          cursor: pointer;
        }

        .__second_row {
          display: flex;
          align-items: center;
          height: 65px;
          border-top: 1px solid $aeroBlue;
          background-color: #f8f8f8;
        }

        .__copy_rights {
          font-family: $fontFamilyPoppins;
          font-style: normal;
          font-weight: normal;
          font-size: 0.875rem;
          line-height: normal;
          text-align: center;
          color: $blackPearl;
          margin-right: 20px;
        }

        .__social_links {
          a {
            img {
              width: 22px;
              height: 22px;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
