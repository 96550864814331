@import "../../assets/scss/colors.scss";
@import "../../assets/scss/fonts.scss";

.__button {
	width: 355px;
	height: 54px;
	border-radius: 16px;
	border-width: 0;
	background-color: $gold;
	color: $white;
	font-family: $fontFamilyPoppins;
	font-weight: bold;
	font-style: normal;
	font-size: 1rem;
	text-align: center;
	cursor: pointer;
	box-shadow: 0px 3px 13px $gold2;
	transition: all 0.4s !important;
}

.__button:hover {
	box-shadow: none;
	background-color: $gold3;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.__button:focus {
	background-color: $gold4;
	box-shadow: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
