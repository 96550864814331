$black: #000000;
$black1: #0e0e2c;
$black2: #191e2f;
$black3: #152544;
$white: #ffffff;
$white1: #ffffffcc;
$white2: #f8f8f8;
$gold: #dda97a;
$gold1: #fff3e3;
$gold2: #dda97a99;
$gold3: #dda97a;
$gold4: #c08f61;
$blackPearl: #081e29;
$blackPearl1: #081e29cc;
$blackPearl2: #081e2999;
$grey: #a3a5ac;
$grey1: #828a9c;
$grey2: #525252;
$grey3: #707a80;
$darkGrey: #10082914;
$darkGrey1: #100829;
$darkGrey2: #2b2a35;
$lightGrey: #a3a5ac;
$aeroBlue: #c5e4d5;
$darkBlue: #404eed;
